import { useAxios } from '../useAxios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useCreateLesson = () => {
    const axios = useAxios();
    const navigate = useNavigate();

    const createLesson = async (formValues) => {
        try {
            const { data } = await axios.post(
                `/instructor/lesson/`,
                formValues
            );

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createLesson, {
        onSuccess: (data) => {
            navigate(`/instructor-lessons/${data.id}`);
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useCreateLesson };
