import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetLessonsById,
    useUpdatePageContent,
    useUpdateLesson,
    useUpdatePage,
    useCreatePageContent,
    useCreatePage,
} from '../../hooks';
import './editInstructorLesson.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/layout/Layout/Layout';

const LessonSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Title is too short')
        .max(50, 'Title is too long')
        .required('Title is required'),
    description: Yup.string()
        .min(1, 'Description is too short')
        .required('Description is required'),
    institution_id: Yup.number()
        .integer('Institution ID must be an integer')
        .required('Institution ID is required'),
});

const PageSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Title must be at least 1 character long')
        .max(50, 'Title cannot exceed 50 characters')
        .required('Title is required'),
});

const EditInstructorLesson = () => {
    const { id } = useParams();
    const { data: lesson } = useGetLessonsById(id);
    const [editorValue, setEditorValue] = useState(
        lesson?.pages.map((page) =>
            page.content.map((content) => content.content || '')
        )
    );
    const updatePageContent = useUpdatePageContent();
    const updateLesson = useUpdateLesson();
    const updatePage = useUpdatePage();
    const [newPageData, setNewPageData] = useState({
        title: 'testTitle',
        content: [
            {
                content: '<p>New page content</p>',
            },
        ],
    });
    const createPage = useCreatePage();
    const [isCreateNewPageOpen, setIsCreateNewPageOpen] = useState(false);

    useEffect(() => {
        const newEditorValue = lesson?.pages.map((page) =>
            page.content.map((content) => content.content || '')
        );
        if (JSON.stringify(newEditorValue) !== JSON.stringify(editorValue)) {
            setEditorValue(newEditorValue);
        }
    }, [lesson]);

    if (!lesson) {
        return <div>Loading...</div>;
    }

    const handleEditorChange = (pageIndex, contentIndex, value) => {
        setEditorValue((prevEditorValue) =>
            prevEditorValue.map((page, index) =>
                index === pageIndex
                    ? page.map((prevValue, idx) =>
                          idx === contentIndex ? value : prevValue
                      )
                    : page
            )
        );
    };

    const savePageContent = (content, pageId, contentId) => {
        updatePageContent.mutate({
            contentId: contentId,
            contentData: content,
            page_id: pageId,
        });
    };

    const initialLessonValues = {
        title: lesson.title,
        description: lesson.description,
        institution_id: lesson.instituion_id,
    };

    const handleLessonSubmit = (values) => {
        updateLesson.mutate({
            lessonId: lesson.id,
            formValues: values,
        });
        console.log(values, 'handle lesson submit');
    };

    const handlePageSubmit = (values, page) => {
        updatePage.mutate({
            pageId: page.id,
            formValues: {
                ...values,
                lesson_id: lesson.id,
                type: page.type,
            },
        });
    };

    const onSubmitNewPage = async (values) => {
        if (newPageData.content.length !== 0) {
            createPage.mutate({
                pageData: {
                    ...values,
                    type: 'gradivo',
                    lesson_id: lesson.id,
                },
                pageContentData: newPageData.content[0].content,
            });
        }
    };

    return (
        <Layout>
            <div className="edit-lesson-page">
                <div className="lesson-data content-container">
                    <h1>Edit lesson</h1>
                    <Formik
                        initialValues={initialLessonValues}
                        validationSchema={LessonSchema}
                        onSubmit={handleLessonSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                {/* Title Field */}
                                <div>
                                    <label htmlFor="title">Title</label>
                                    <Field
                                        type="text"
                                        name="title"
                                        placeholder="Enter the lesson title"
                                    />
                                    <ErrorMessage
                                        name="title"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Description Field */}
                                <div>
                                    <label htmlFor="description">
                                        Description
                                    </label>
                                    <Field
                                        type="text"
                                        name="description"
                                        placeholder="Enter a description"
                                    />
                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Institution ID Field */}
                                <div>
                                    <label htmlFor="institution_id">
                                        Institution ID
                                    </label>
                                    <Field
                                        type="number"
                                        name="institution_id"
                                        placeholder="Enter institution ID"
                                    />
                                    <ErrorMessage
                                        name="institution_id"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                {/* Submit Button */}
                                <div>
                                    <button
                                        type="submit"
                                        className="save-button"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="pages-container content-container">
                    {lesson.pages.map((page, pageIndex) => (
                        <div className="page" key={page.id}>
                            <Formik
                                initialValues={{ title: page.title }}
                                validationSchema={PageSchema}
                                onSubmit={(values) =>
                                    handlePageSubmit(values, page)
                                }
                                className="page-title"
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div>
                                            <label htmlFor="title">Title</label>
                                            <Field
                                                type="text"
                                                name="title"
                                                placeholder="Enter the title"
                                            />
                                            <ErrorMessage
                                                name="title"
                                                component="div"
                                                className="error"
                                            />
                                        </div>

                                        {page.content.map(
                                            (content, contentIndex) => (
                                                <>
                                                    {editorValue &&
                                                    editorValue[pageIndex] ? (
                                                        <div className="content">
                                                            <ReactQuill
                                                                value={
                                                                    editorValue[
                                                                        pageIndex
                                                                    ][
                                                                        contentIndex
                                                                    ]
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    handleEditorChange(
                                                                        pageIndex,
                                                                        contentIndex,
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <button
                                                        className="save-button"
                                                        type="submit"
                                                        onClick={() =>
                                                            savePageContent(
                                                                editorValue[
                                                                    pageIndex
                                                                ][contentIndex],
                                                                page.id,
                                                                content.id
                                                            )
                                                        }
                                                    >
                                                        Save
                                                    </button>
                                                </>
                                            )
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ))}
                    <div>
                        {!isCreateNewPageOpen ? (
                            <button
                                className="create-page-button save-button"
                                onClick={() => setIsCreateNewPageOpen(true)}
                            >
                                Create new page
                            </button>
                        ) : (
                            <div className="pages-container content-container">
                                <h1>Create New Page</h1>
                                <div className="page">
                                    <Formik
                                        initialValues={{
                                            title: newPageData.title,
                                        }}
                                        validationSchema={PageSchema}
                                        onSubmit={(values) => {
                                            onSubmitNewPage(values);
                                        }}
                                        className="page-title"
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div>
                                                    <label htmlFor="title">
                                                        Title
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        placeholder="Enter the title"
                                                    />
                                                    <ErrorMessage
                                                        name="title"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>

                                                {newPageData.content.map(
                                                    (content, contentIndex) => (
                                                        <>
                                                            <div className="content">
                                                                <ReactQuill
                                                                    value={
                                                                        content.content
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        console.log(
                                                                            {
                                                                                ...newPageData,
                                                                                content:
                                                                                    newPageData.content.map(
                                                                                        (
                                                                                            prevContent,
                                                                                            i
                                                                                        ) =>
                                                                                            i ===
                                                                                            contentIndex
                                                                                                ? value
                                                                                                : prevContent
                                                                                    ),
                                                                            },
                                                                            'papappa'
                                                                        );
                                                                        setNewPageData(
                                                                            {
                                                                                ...newPageData,
                                                                                content:
                                                                                    newPageData.content.map(
                                                                                        (
                                                                                            prevContent,
                                                                                            i
                                                                                        ) =>
                                                                                            i ===
                                                                                            contentIndex
                                                                                                ? {
                                                                                                      content:
                                                                                                          value,
                                                                                                  }
                                                                                                : {
                                                                                                      content:
                                                                                                          prevContent,
                                                                                                  }
                                                                                    ),
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <button
                                                                className="create-page-button save-button"
                                                                type="submit"
                                                            >
                                                                Create page
                                                            </button>
                                                        </>
                                                    )
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditInstructorLesson;
