import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';  
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 

const Layout = ({ children }) => {

    return( 
        <>
        <Header/>
        {children}
        <Footer/>
        </>
    )

}
export default Layout;
