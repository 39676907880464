import axios from 'axios';
import { useMutation } from 'react-query';
import { useUser } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';

const useStandardLogin = () => {
    const { setUser } = useUser();
    const navigate = useNavigate();

    const standardLogin = async (values) => {
        try {
            const { data } = await axios.post(
                `https://api.djangopasta.live/api/user/login/`,
                values,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            const { data: user } = await axios.get(
                `https://api.djangopasta.live/api/user/me/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${data.access_token}`,
                    },
                }
            );
            setUser(user);

            return data;
        } catch (error) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            throw new Error(error.message);
        }
    };

    return useMutation(standardLogin, {
        onSuccess: (data) => {
            navigate('/');
            console.log(data, 'user login data');
        },
    });
};

export { useStandardLogin };
