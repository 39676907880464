import React from 'react';
import './Homepage.scss';

const Homepage = () => {
    return (
        <div className="homepage">
            <h1>Django Pasta</h1>

            <h2>Opis projekta</h2>
            <p>
                Ovaj projektni zadatak ima za cilj stvoriti interaktivnu
                platformu za e-učenje koja bi prvenstveno bila usmjerena na
                učenike osnovnih i srednjih škola, olakšavajući im učenje
                programiranja putem strukturiranih lekcija i praktičnih
                zadataka. U nastavku je razrada ključnih aspekata projekta,
                uključujući korisničke zahtjeve, postojeća rješenja,
                potencijalne korisnike, mogućnost prilagodbe, te mogućnosti
                nadogradnje i proširenja sustava.
            </p>
            <h2>Korist ovog projekta</h2>
            <p>
                Cilj projekta je stvoriti platformu za e-učenje koja omogućava
                učenicima stjecanje znanja iz programiranja na pristupačan,
                interaktivan i prilagođen način. Razvojem vještina kroz
                teorijske i praktične lekcije, učenici uče osnovne koncepte, te
                nego ih i primjenjuju kroz zadatke i evaluacije.
            </p>
            <ul>
                <li>
                    Povećanje interesa za programiranje: Prilagodljivost i
                    interaktivnost platforme mogu povećati interes učenika za
                    programiranje, čime se promiče razvoj STEM vještina.
                </li>
                <li>
                    Olakšan pristup edukaciji: Online materijali omogućuju
                    učenicima u udaljenim područjima pristup kvalitetnim
                    resursima za učenje.
                </li>
                <li>
                    Podrška obrazovnom sustavu: Platforma može poslužiti kao
                    dodatni resurs učiteljima i profesorima za dopunsko
                    obrazovanje učenika.
                </li>
            </ul>
            <h2>Što sve projekt uključuje</h2>
            <p>
                Projekt se sastoji od nekoliko ključnih komponenti, uključujući:
            </p>
            <ul>
                <li>
                    Modul za prijavu korisnika: Omogućava registraciju putem
                    e-maila i lozinke ili OAuth integraciju s Googleom i
                    Microsoftom.
                </li>
                <li>
                    Sustav za lekcije i zadatke: Organizacija sadržaja u
                    cjeline, lekcije i zadatke s praćenjem napretka i
                    nagrađivanjem simboličnim nagradama.
                </li>
                <li>
                    Evaluacijski mikroservis: Evaluira korisnički kod unutar
                    izoliranog okruženja kako bi se osigurala sigurnost i
                    validacija rezultata.
                </li>
                <li>
                    ChatBot za podršku učenju: Omogućava korisnicima
                    interaktivnu podršku i odgovore na pitanja u vezi s
                    lekcijom.
                </li>
                <li>
                    Admin panel za nastavnike: Omogućava dodavanje, uređivanje i
                    uklanjanje lekcija i zadataka.
                </li>
            </ul>
            <h2>Budući potencijal</h2>
            <p>Neki od prijedloga za buduće funkcionalnosti uključuju:</p>
            <ul>
                <li>
                    Proširenje na napredne tečajeve: Osim početničkih lekcija,
                    mogli bi se dodati tečajevi za naprednije koncepte
                    programiranja.
                </li>
                <li>
                    Razvoj mobilne aplikacije: Omogućavanje korisnicima da
                    pristupe lekcijama putem mobilnih uređaja.
                </li>
            </ul>
        </div>
    );
};

export default Homepage;
