import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';
import { useCreatePageContent } from './useCreatePageContent';

const useCreatePage = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();
    const createPageContent = useCreatePageContent();

    const createPage = async ({ pageData, pageContentData }) => {
        try {
            const { data } = await axios.post(`/page/`, pageData);

            createPageContent.mutate({
                page_id: data.id,
                content: pageContentData,
            });

            console.log(data, 'create page');

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createPage, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useCreatePage };
