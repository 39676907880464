import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetLessonsById } from '../../hooks';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout/Layout';



const ChosenLesson = () => {
    const { id: lessonID } = useParams();
    const { data: lesson } = useGetLessonsById(lessonID);

    if (!lesson) {
        return <p>Loading...</p>;
    }

    //console.log("lesson id: " , lessonID);
    let i = 0;
    return (
        <Layout>
        <div className='d-flex flex-column align-items-center'>
        <div className="container mt-5">
            <div className="row">
                <p className="bg-danger-subtle">Lesson title: {lesson.title}</p>
                <p className="bg-danger-subtle">
                    Lesson description: {lesson.description}
                </p>
            </div>
        </div>

        <nav aria-label="Page navigation example">
        <ul className="pagination">
        {lesson.pages.map((page, index) => (
            <li className="page-item" key={`lessons/${lessonID}/${page.id}`}>
                <Link state={page} className="page-link" to={`/lessons/${lessonID}/${page.id}`}>
                    {index + 1}
                </Link>
            </li>
        ))}
          
        </ul>
        </nav>
        </div>
        </Layout>
    );
};

export default ChosenLesson;
