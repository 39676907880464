import axios from 'axios';
import { QueryClient, useMutation } from 'react-query';
import { useUser } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';

const useStandardRegistration = () => {
    // const queryClient = QueryClient();
    const { setUser } = useUser();
    const navigate = useNavigate();

    const standardRegistration = async (values) => {
        try {
            const { data } = await axios.post(
                `https://api.djangopasta.live/api/user/register/`,
                values,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            localStorage.setItem('access_token', data.access);
            localStorage.setItem('refresh_token', data.refresh);

            const { data: user } = await axios.get(
                `https://api.djangopasta.live/api/user/me/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${data.access_token}`,
                    },
                }
            );

            setUser(user);

            return data;
        } catch (error) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            throw new Error(error.message);
        }
    };

    return useMutation(standardRegistration, {
        onSuccess: (data) => {
            navigate('/');
            console.log(data, 'user login data');
        },
    });
};

export { useStandardRegistration };
