import { useContext } from 'react';
import { AxiosContext } from '../context/axiosContext';


const useAxios = () => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('useAxios must be used within an AxiosContextProvider');
  }
  return context;
};


export { useAxios };