import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useGetLessons } from '../../hooks';
import Layout from '../../components/layout/Layout/Layout';

const PageChosen = () => {

    const location = useLocation();
    const page = location.state;

    return(
        <Layout>
        <div>
            <h1 className='mt-5 bg-danger-subtle'>Page title: {page.title}</h1>
            <h4 className='mt-5 bg-dark-subtle'>Page content: {page.content[0].content}</h4>

        </div>
        </Layout>
    )

}

export default PageChosen