import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useCreatePageContent = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const createPageContent = async (formValues) => {
        try {
            console.log(formValues, 'create page content');
            const { data } = await axios.post(`/page-content/`, formValues);

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(createPageContent, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons'],
            });
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useCreatePageContent };
