import { createContext, useMemo } from 'react';
import Axios from 'axios';
import { useUser } from './userContext';

const AxiosContext = createContext(Axios);
//const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000/api';
const baseUrl = 'https://api.djangopasta.live/api';

const AxiosContextProvider = ({ children }) => {
    const { setUser } = useUser();

    const getUserInfo = async (token) => {
        const { data: user } = await Axios.get(
            `${baseUrl}/user/me/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setUser(user);
    };

    const axiosInstance = useMemo(() => {
        const token = localStorage.getItem('access_token');

        const axios = Axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axios.interceptors.request.use(
            (config) => {
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        if (token) {
            getUserInfo(token);
        }

        return axios;
    }, []);

    return (
        <AxiosContext.Provider value={axiosInstance}>
            {children}
        </AxiosContext.Provider>
    );
};

export { AxiosContext, AxiosContextProvider };
