import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useUpdateLesson = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const updateLesson = async ({ lessonId, formValues }) => {
        try {
            const { data } = await axios.put(
                `/instructor/lesson/${lessonId}/`,
                formValues
            );

            console.log(data, 'update lesson');
            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(updateLesson, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['lessons'] });
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useUpdateLesson };
