import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useStandardLogin } from '../../../hooks/auth/useStandardLogin';
import { useUser } from '../../../context/userContext';
import { useGetCurrentUser } from '../../../hooks';
import '../form.scss';

// Yup validation schema
const validationSchema = Yup.object({
    username: Yup.string()
        .required('Username is required')
        .min(3, 'Username must be at least 3 characters'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?!.*(?:password|1234|qwerty|abc123)).*$/,
            'Password can’t be a commonly used password'
        )
        .matches(/\d/, 'Password can’t be entirely numeric')
        .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
        .notOneOf(
            [Yup.ref('username'), Yup.ref('email')],
            'Password can’t be too similar to your other personal information'
        ),
});

const Login = () => {
    const standardLogin = useStandardLogin();

    return (
        <div className="login-form-container">
            <h1>Login</h1>
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    standardLogin.mutate(values);
                    // Here you can handle the form submission, e.g., call an API
                }}
            >
                <Form>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <Field
                            type="text"
                            id="username"
                            name="username"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="username"
                            component="div"
                            className="error"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                            type="password"
                            id="password"
                            name="password"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                        />
                    </div>

                    <button type="submit" className="submit-btn">
                        Submit
                    </button>
                </Form>
            </Formik>
        </div>
    );
};

export default Login;
