import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useGetLessons } from '../../hooks';
import Layout from '../../components/layout/Layout/Layout';

//description: "desc" id: 1 instituion_id: 1 title: "newLesson1"

const Lessons = () => {
    

    const { data: lesson } = useGetLessons();

    if (!lesson) {
        return <p>Loading...</p>;
    }

    //console.log("ddd" ,lesson)

    return (
        <Layout>
        <div className="container-fluid mt-4">
            <div className="row mb-5">
                <div className="col-2">
                    <button className="btn btn-outline-success" type="submit">
                        Filter
                    </button>
                </div>

                <div className="col row justify-content-end">
                    <div className="col-5">
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </div>
                    <div className="col-3">
                        <button
                            className="btn btn-outline-success"
                            type="submit"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>

            <div className="row justify-content-around">
                {lesson.map((lesson) => (
                    <div className="col-6 col-md-4 mb-3 h-25" key={lesson.id}>
                        <div className="card h-50 w-100">
                            <img
                                src="https://picsum.photos/200/300"
                                className="card-img-top h-25"
                                alt="Lesson"
                            />
                            <div className="card-body text-center">
                                <h5 className="card-title">{lesson.title}</h5>
                                <p className="card-text">
                                    {lesson.description}
                                </p>
                                <Link
                                    to={`/lessons/${lesson.id}`}
                                    state={lesson}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </Layout>
    );
};

export default Lessons;
