import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import AppRouter from './routes/AppRouter';
import { AxiosContextProvider } from './context/axiosContext';
import Layout from './components/layout/Layout/Layout';
import { UserProvider } from './context/userContext';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <AxiosContextProvider>
                    <RouterProvider router={AppRouter} />
                </AxiosContextProvider>
            </UserProvider>
        </QueryClientProvider>
    );
};

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
