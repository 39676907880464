import React from 'react';
import { useState } from 'react';
import { motion } from 'framer-motion';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Footer() {
    return (
        <div
            className="container-fluid fw-normal text-info border border-primary"
            style={{ backgroundColor: '#1c2331', marginTop: '320px' }}
        >
            <div className="row">
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                    <motion.h3
                        className="mt-3"
                        variants={footerHeader1}
                        initial="hidden"
                        animate="visible"
                        whileHover={{
                            scale: 1.1,
                            textShadow: '0px 0px 8px #0dcaf0',
                        }}
                    >
                        DjagnoPasta
                    </motion.h3>
                    <hr className="mb-4 mt-0 block w-25 bg-danger" />
                    <motion.p
                        variants={textFooterOpacity}
                        initial="hidden"
                        animate="visible"
                        className="fs-4 text-light text-center"
                    >
                        t is a long establis a more-or-less normal distribution
                        of letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy
                    </motion.p>
                </div>

                <div className="col-6 col-md-4  d-flex flex-column align-items-center">
                    <motion.h3
                        className="mt-3"
                        variants={footerHeader2}
                        initial="hidden"
                        animate="visible"
                        whileHover={{
                            scale: 1.1,
                            textShadow: '0px 0px 8px #0dcaf0',
                        }}
                    >
                        Korisni linkovi
                    </motion.h3>
                    <div className="d-flex flex-column align-items-center w-100">
                        <hr className="mb-4 mt-0 block h-25 w-25" />
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Link 1
                        </motion.p>
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Link 1
                        </motion.p>
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Link 1
                        </motion.p>
                    </div>
                </div>

                <div className="col-6 col-md-4 d-flex flex-column align-items-center">
                    <motion.h3
                        className="mt-3"
                        variants={footerHeader3}
                        initial="hidden"
                        animate="visible"
                        whileHover={{
                            scale: 1.1,
                            textShadow: '0px 0px 8px #0dcaf0',
                        }}
                    >
                        Kontakt
                    </motion.h3>
                    <div className="d-flex flex-column align-items-center">
                        <hr className="mb-4 mt-0 block h-25 w-25  w-100" />
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Kontakt 1
                        </motion.p>
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Kontakt 1
                        </motion.p>
                        <motion.p
                            className="m-4 text-light"
                            variants={textFooterOpacity}
                            initial="hidden"
                            animate="visible"
                        >
                            Kontakt 1
                        </motion.p>
                    </div>
                </div>
            </div>

            <div className="row">
                <motion.div
                    className="col d-flex justify-content-center align-items-center"
                    style={{
                        height: '40px',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    }}
                    variants={textFooterOpacity}
                    initial="hidden"
                    animate="visible"
                >
                    &#169; 2024 Copyright
                </motion.div>
            </div>
        </div>
    );
}

const footerHeader1 = {
    hidden: {
        x: '-100vw',
    },

    visible: {
        x: 0,
        transition: {
            type: 'tween',
            delay: 0.2,
            duration: 0.6,
        },
    },
};

const footerHeader3 = {
    hidden: {
        x: '100vw',
    },

    visible: {
        x: 0,
        transition: {
            type: 'tween',
            delay: 0.2,
            duration: 0.6,
        },
    },
};

const footerHeader2 = {
    hidden: {
        y: '100vw',
    },

    visible: {
        y: 0,
        transition: {
            type: 'tween',
            delay: 0.2,
            duration: 0.6,
        },
    },
};

const textFooterOpacity = {
    hidden: {
        opacity: 0,
    },

    visible: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 3,
        },
    },
};
