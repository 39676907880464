import { useAxios } from '../useAxios';
import { useMutation, useQueryClient } from 'react-query';

const useUpdatePage = () => {
    const axios = useAxios();
    const queryClient = useQueryClient();

    const updatePage = async ({ pageId, formValues }) => {
        try {
            console.log(formValues, 'update page');
            const { data } = await axios.put(`/page/${pageId}/`, formValues);

            return data;
        } catch (error) {
            console.error('Error fetching instructor lessons:', error);
            throw new Error(error.message);
        }
    };

    return useMutation(updatePage, {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['lessons', data.lesson_id],
            });
            console.log('Data fetched successfully:', data);
        },
        onError: (error) => {
            console.error('Error fetching instructor lessons:', error);
        },
    });
};

export { useUpdatePage };
