import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../context/userContext';
import { useGetCurrentUser } from '../hooks';

const AuthorizedRoutes = ({ authorizedRoles }) => {
    const { user } = useUser();
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
        return <Navigate to="/login" />;
    }

    if (!user) {
        return <p>Loading</p>;
    }

    const isAuthorized = user && authorizedRoles.includes(user.role);

    return <Outlet />;
};

export { AuthorizedRoutes };
